import axios from "axios";
import store from "@/store";
import router from "@/router";
import Cookies from "js-cookie";

const api = axios.create({
  baseURL: store.state.apiBaseURL,
  timeout: 30000,
});

api.interceptors.request.use(function (config) {
  const token =
    Cookies.get("auth_token") ||
    Cookies.get("guest_token") ||
    Cookies.get("refresh_token");

  config.headers.Authorization = `Bearer ${token}`;

  return config;
});

async function logout() {
  store.commit("logout");

  await api.post("auth/guest")
  .then((response) => {
    const tkngst = response.data.response.guest_token
    store.dispatch("guest", tkngst);
    router.go();
  })
  .catch((error) => {
    switch (error.response?.data.status_code) {
      case 500:
        store.state.errorPage = 500
        router.push({name: "service-error"})
        break;
      default:
        store.state.errorPage = true
        break;
    }
  })
}

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    switch (error?.response?.status) {
      case 406: {
        const refreshToken = Cookies.get("refresh_token");
        Cookies.remove("auth_token");

        if (refreshToken) {
          api
            .get("auth/refresh-token", {
              headers: {
                Authorization: `Bearer ${refreshToken}`,
              },
            })
            .then((response) => {
              Cookies.set("auth_token", response.data.response.auth_token);
              router.go();
            })
            .catch(async () => {
              logout;
            });
        } else {
          logout();
        }

        break;
      }
      default: {
        throw error;
      }
    }
  }
);

export default api;
