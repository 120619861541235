<template>
  <v-app class="bg-main-background">
    <router-view />
  </v-app>
</template>

<script>

export default {
  name: "App",
};
</script>
<style lang="scss">
@use "./plugins/main.scss";

* {
  font-family: main.$body-font-family;
}

.v-application {
  [class*="text-"] {
    font-family: main.$body-font-family !important;
  }

  font-family: main.$body-font-family !important;
}
</style>

<style>
a {
  text-decoration: none;
  color: black;
}

.bg-color {
  background-color: #f8f8f8;
}

.sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 100px;
}
</style>